import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetProspectiveRequest, GetEmployeeRequest, GetLocationRequest, ExportEmployeeRequest, DeleteEmployeeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';

const EmployeeList = () => {

  const column = [
    {
      "key": "First Name",
      "value": "First_Name",
      "ischecked": true
    },
    {
      "key": "Last Name",
      "value": "Last_Name",
      "ischecked": true
    },
    {
      "key": "Status",
      "value": "Status",
      "ischecked": true
    },
    {
      "key": "Mobile Phone",
      "value": "Mobile_Phone",
      "ischecked": true
    },
    {
      "key": "Other Phone",
      "value": "Other_Phone",
      "ischecked": true
    },
    {
      "key": "Email",
      "value": "Email",
      "ischecked": true
    },
    {
      "key": "Address",
      "value": "Street_Address_1",
      "ischecked": true
    },
    {
      "key": "Description",
      "value": "Description",
      "ischecked": true
    },
    {
      "key": "Country",
      "value": "country_id",
      "ischecked": true
    },
    {
      "key": "State",
      "value": "state_id",
      "ischecked": true
    },
    {
      "key": "City",
      "value": "city_id",
      "ischecked": true
    },


  ]

  const [numberpagination, setnumberpagination] = useState([])
  const [active, setactive] = useState(0)
  const [location, setlocation] = useState("")
  const [name, setname] = useState("")
  const [status, setstatus] = useState("")
  const [show, setShow] = useState(false);
  const [columnName, setColumnName] = useState(column)
  const [isCheckAll, setIsCheckAll] = useState(true);
  const [employeeType, setemployeeType] = useState("")
  const [employeementType, setemployeementType] = useState("")
  const handleClose = () => setShow(false);


  const dispatch = useDispatch()
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var propectList = fetchacompanyReducer?.GetEmployeeResponse?.results
  let Dlocation = JSON.parse(localStorage.getItem("defaultLocation"));
  const locationReducer = useSelector((state) => state.LocationSlice)
  let locationId = localStorage.getItem("locationId");

  console.log('location', fetchacompanyReducer)

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleShow = () => {
    setShow(true)
    setColumnName(column)
    const link = document.getElementById("Download");
    link.removeAttribute("href");
  };

  useEffect(() => {




    let obj = {
      "page_size": 10,
      'page': 1,
      'name': '',
      'status': '',
      'employee_type': '',
      'employment_type': '',
      'location_id': locationId == null ? '' : locationId
    }

    dispatch(GetEmployeeRequest(obj))
  }, [])

  useEffect(() => {
    let obj = {
      "page_size": 10,
      'page': 1,
      'name': '',
      'status': '',
      'employee_type': '',
      'employment_type': '',
      'location_id': locationId == null ? '' : locationId
    }

    dispatch(GetEmployeeRequest(obj))

  }, [locationReducer.locationList])

  function isEmpty(item) {
    if (item == '' || item == null || item == undefined) return true; return false;
  }
  useEffect(() => {
    if (!isEmpty(fetchacompanyReducer?.GetEmployeeResponse)) {
      if (fetchacompanyReducer?.GetEmployeeResponse?.total_stats && fetchacompanyReducer?.GetEmployeeResponse?.total_stats.length > 0)
        var totalLength = fetchacompanyReducer?.GetEmployeeResponse?.total_stats[0]?.total_count

      console.log('GetEmployeeResponse?.total_stats', totalLength)

      var count = totalLength / 10
      var roundof = Math.ceil(count)
      let pagArr = []

      for (let i = 0; i < roundof; i++) {
        pagArr.push(i)
      }
      setnumberpagination(pagArr)
    }
  }, [fetchacompanyReducer?.GetEmployeeResponse])



  const paginateHandle = (number) => {
    setactive(number - 1)
    console.log(number)
    let obj = {
      "page_size": 10,
      'page': number,
      'name': '',
      'status': '',
      'employee_type': '',
      'employment_type': '',
      'location_id': locationId
    }

    dispatch(GetEmployeeRequest(obj))
  }

  const SearchHandle = () => {
    let obj = {
      "page_size": 10,
      'page': 1,
      'name': name,
      'status': status,
      'employee_type': employeeType,
      'employment_type': employeementType,
      'location_id': locationId == null ? '' : locationId
    }

    dispatch(GetEmployeeRequest(obj))
  }

  const ResetHandle = () => {
    setname("")
    setstatus("")
    setlocation("")
    let obj = {
      "page_size": 10,
      'page': 1,
      'name': '',
      'status': '',
      'employee_type': '',
      'employment_type': '',
      'location_id': ''
    }

    dispatch(GetEmployeeRequest(obj))
  }


  const CheckboxHandle = (index, e) => {
    const { value, checked } = e.target;
    let raw = [...columnName]
    if (checked) {
      raw[index].ischecked = true
    } else {
      setIsCheckAll(false)
      raw[index].ischecked = false
    }
    setColumnName(raw)
  }

  const ExportHandle = () => {

    let Arr = []

    columnName.forEach(element => {

      if (element.ischecked) {
        Arr.push(element.value)
      }

    });

    let obj = {
      "column_selector": Arr
    }

    if (obj.column_selector.length > 1) {
      dispatch(ExportEmployeeRequest(obj))
      setShow(false)
    } else {
      Swal.fire({
        icon: "error",
        text: 'Choose atleast two columns',
      });
    }


  }

  useEffect(() => {
    let obj = {
      "page_size": 10,
      'page': 1,
      'name': '',
      'status': '',
      'employee_type': '',
      'employment_type': '',
      'location_id': locationId == null ? '' : locationId
    }

    dispatch(GetEmployeeRequest(obj))
  }, [fetchacompanyReducer?.DeleteEmployeeResponse])


  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteEmployeeRequest(id))

        if (fetchacompanyReducer?.DeleteEmployeeResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AllSelectHandle = (e) => {
    const { name, checked } = e.target


    var update = columnName.map((item) => {
      setIsCheckAll(!isCheckAll);
      if (checked) {
        return { ...item, ischecked: true }
      } else {
        return { ...item, ischecked: false }
      }
    })
    setColumnName(update)

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      SearchHandle();
    }
  };


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> Employee Management</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='row form-style mb-4 align-items-center'>
            <div className='col-lg-3'>
              <div className='form-group'>
              <label>Name</label>
        <input
          type='text'
          className='form-control'
          placeholder='Search..'
          value={name}
          onChange={(e) => setname(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
        />
      </div>
    </div>

    <div className='col-lg-3'>
      <div className='form-group'>
        <label>Employee Type</label>
        <select
          className='form-control'
          value={employeeType}
          onChange={(e) => setemployeeType(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          <option value="">--Employee Type---</option>
          <option value="Corporate Employee">Corporate Employee</option>
          {userinfo?.user_type === "COMPANY" && (
            <>
              <option value="Franchise Owned">Franchise Owned</option>
              <option value="Corporate Owned">Corporate Owned</option>
              <option value="Applicant">Applicant</option>
            </>
          )}
        </select>
      </div>
    </div>

    <div className='col-lg-3'>
      <div className='form-group'>
        <label>Employment Type</label>
        <select
          className='form-control'
          value={employeementType}
          onChange={(e) => setemployeementType(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          <option value="">--Employment Type---</option>
          <option value="parmerent">Full Time</option>
          <option value="part time">Part Time</option>
        </select>
      </div>
    </div>

    <div className='col-lg-2'>
      <div className='form-group'>
        <label>Status</label>
        <select
          className='form-control'
          value={status}
          onChange={(e) => setstatus(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
        >
          <option value="">--Status---</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    </div>
            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='d-block'>&nbsp;</label>
                <div className='d-flex'>
                  <button className='btn btn-primary mr-3' onClick={SearchHandle}>Search</button>
                  <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='button-part mb-4'>
          <ul>
            <li>
              <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/employee/add-employee`}><i className="fa-solid fa-plus"></i> Add Employee</NavLink>
            </li>
            <li>
              <button className='formBtn1' onClick={handleShow}><i className="fa-solid fa-file-excel"></i> Export </button>
            </li>
          </ul>
        </div>
        <a id="Download" download></a>
        {propectList && propectList.length > 0 ?
          <div className='table-responsive'>
            <table className='custom-table'>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Title</th>
                  <th>Email</th>
                  <th>Mobile no</th>
                  <th>Hire Date</th>
                  <th>Inactive Date</th>
                  <th>Status</th>
                  <th>Primary Location</th>
                  <th>No. of Locations</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {propectList && propectList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.title_name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.mobile_phone}</td>
                      <td>{item?.hire_date}</td>
                      <td>{item?.end_date}</td>
                      <td>{item?.status ? toTitleCase(item.status) : ''}</td>
                      <td>{item?.locations && item?.locations.map((list, index) => {
                        return (
                          list?.location_name + ',' + ' '
                        )
                      })}</td>
                      <td>{item?.total_location_count}</td>
                      <td>
                        <NavLink to={`/${userinfo?.company_slug}/employee/view/${item?.user_id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                        <NavLink to={`/${userinfo?.company_slug}/employee/edit/${item?.user_id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                        <button onClick={() => DeleteHandle(item?.id)}><i className="fa-solid fa-trash"></i></button>
                      </td>
                    </tr>
                  )
                })}



              </tbody>

            </table>
          </div>
          :
          <h6>No data found</h6>
        }
        {numberpagination.length > 10 &&
          <ul className='pagination'>
            {numberpagination && numberpagination.map((number, i) => {
              return (
                <li key={i}><button className={active == number ? 'active' : null} onClick={() => paginateHandle(number + 1)}>{number + 1}</button></li>
              )
            })}

          </ul>
        }

      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Export CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className='mb-3'>Select Column</h6>
          <input type="checkbox" name='selectall' id="s_all" onChange={AllSelectHandle} checked={isCheckAll} /> <label htmlFor='s_all'>Select all</label>
          <ul className='columnlist'>
            {columnName.map((column, index) => {
              return (
                <li key={index}>
                  <input type="checkbox" name="column" id={`column${index}`} value={column.value} onChange={(e) => CheckboxHandle(index, e)} checked={column.ischecked ? true : false} />
                  <label htmlFor={`column${index}`}>{column.key}</label>
                </li>
              )

            })}

          </ul>

        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-warning' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-primary' onClick={ExportHandle}>
            Export Now
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EmployeeList