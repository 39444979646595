import React, { useEffect, useState, version } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddquesRequest, GetGradeRequest, GetModuleTypeRequest, GetModuleRequest, GetSubjectRequest, GetTopicRequest, GetTopicChangeRequest, GetModuleChangeRequest, GetSyllabusChangeRequest, ViewQuestionRequest, FetchtestRequest, UpdateQuestionRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import moment from "moment";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Accordion from 'react-bootstrap/Accordion';

const EditTest = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectResponse?.results
    var testdata = fetchacompanyReducer?.GettestResponse?.results[0]
    var moduleList = fetchacompanyReducer?.GetModuleChangeResponse?.results
    var topicList = fetchacompanyReducer?.GetTopicChangeResponse?.results
    var SyllabusList = fetchacompanyReducer?.GetSyllabusChangeResponse?.results



    console.log("view question", fetchacompanyReducer.ViewQuestionResponse)

    useEffect(() => {
        let obj = {
            'name': '',
            'group_id': '',
        }

        let obj2 = {
            'name': '',
            'group_id': ''
        }

        let obj3 = {
            'name': '',
            'module_id': '',
            'group_id': '',
            'syllabus_id': ''
        }

        dispatch(GetGradeRequest())
        dispatch(GetSyllabusChangeRequest())
        dispatch(GetSubjectRequest(obj))
        dispatch(GetModuleRequest(obj2))
        dispatch(GetTopicRequest(obj3));
    }, [])

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo'],
        placeholder: fetchacompanyReducer.ViewQuestionResponse?.results?.[0]?.question
    };

    const { id, pros } = useParams()
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const [grade, setgrade] = useState("")
    const [subject, setsubject] = useState("")
    const [syllabus, setsyllabus] = useState("")
    const [question, setquestion] = useState("")
    const [answerone, setanswerone] = useState("")
    const [answertwo, setanswertwo] = useState("")
    const [answerthree, setanswerthree] = useState("")
    const [answerfour, setanswerfour] = useState("")
    const [correctanswer, setcorrectanswer] = useState("")
    const [ques_hint, setques_hint] = useState("")
    const [ques_marks, setques_marks] = useState("")
    const [modulename, setmodulename] = useState("")
    const [topicname, settopicname] = useState("")



    const ref = React.useRef(null);
    const [src, setSrc] = useState('');




    const ModuleHandle = (value) => {
        setmodulename(value)
        dispatch(GetTopicChangeRequest(value))
    }

    const SubjectHandle = (e) => {
        setsubject(e.target.value)
        let obj = {
            id: e.target.value
        }
        // dispatch(GetModuleChangeRequest(obj))
        dispatch(GetSyllabusChangeRequest(obj))
    }

    const SyllabusHandle = (e) => {
        setsyllabus(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetModuleChangeRequest(obj))
    }


    const SubmitHandle = () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (grade == '') {
            Swal.fire({
                icon: "error",
                text: 'Grade should be must',
            });
        }
        // else if (subject == '') {
        //     Swal.fire({
        //         icon: "error",
        //         text: 'Subject should be must',
        //     });
        // }

        else {

            var obj = {
                "module_id": Number(modulename),
                "topic_id": Number(topicname),
                "question": question,
                "question_hints": ques_hint,
                "question_image": "",
                "question_marks": Number(ques_marks),
                "option_1": answerone,
                "option_1_image": "",
                "option_2": answertwo,
                "option_2_image": "",
                "option_3": answerthree,
                "option_3_image": "",
                "option_4": answerfour,
                "option_4_image": "",
                "answer": Number(correctanswer)
            }

            dispatch(UpdateQuestionRequest({ obj: obj, id: pros, test_id: id, navigate: navigate }))
        }
    }






    useEffect(() => {
        dispatch(GetModuleTypeRequest())
    }, [])


    useEffect(() => {
        let obj = {
            test_id: id,
            id: pros,
        }
        dispatch(ViewQuestionRequest(obj))

        let obj1 = {
            id: id
        }
        dispatch(FetchtestRequest(obj1))
    }, [])


    useEffect(() => {
        var viewQuestion = fetchacompanyReducer.ViewQuestionResponse?.results?.[0]
        setgrade(viewQuestion?.grade_id)
        setsubject(viewQuestion?.subject_id)
        setmodulename(viewQuestion?.module_id)
        settopicname(viewQuestion?.topic_id)
        setquestion(viewQuestion?.question)
        setques_hint(viewQuestion?.question_hints)
        setques_marks(viewQuestion?.question_marks)
        setanswerone(viewQuestion?.option_1)
        setanswertwo(viewQuestion?.option_2)
        setanswerthree(viewQuestion?.option_3)
        setanswerfour(viewQuestion?.option_4)
        setcorrectanswer(viewQuestion?.answer)
        setsyllabus(testdata?.syllabus_id)

        let obj = {
            id: viewQuestion?.subject_id
        }
        dispatch(GetSyllabusChangeRequest(obj))

        let objone = {
            id: testdata?.syllabus_id
        }
        dispatch(GetModuleChangeRequest(objone))

        dispatch(GetTopicChangeRequest(viewQuestion?.module_id))

    }, [fetchacompanyReducer?.ViewQuestionResponse && fetchacompanyReducer?.GettestResponse])

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Edit Questions</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Grade<span class="asterisk">*</span></label>
                            <select className='form-control' value={grade}
                                disabled
                                onChange={(e) => setgrade(e.target.value)}>
                                <option value="">--Grade--</option>
                                {GradeList && GradeList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.grade_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Subject<span class="asterisk">*</span></label>
                            <select className='form-control' value={subject}
                                disabled
                                onChange={SubjectHandle}>
                                <option value="">--subject--</option>
                                {SubjectList && SubjectList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.subject_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Syllabus<span class="asterisk">*</span></label>
                            <select className='form-control' value={syllabus}
                                disabled
                                onChange={SyllabusHandle}>
                                <option value="">--syllabus--</option>
                                {SyllabusList && SyllabusList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>



                    <div className='col-lg-12'>
                        <div className='row'>
                            {testdata?.test_type != 'group' &&

                                <div className='col-lg-5'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Module</label>
                                        <select className='form-control' value={modulename}
                                            onChange={(e) => ModuleHandle(e.target.value)}>
                                            <option value="">--Module---</option>
                                            {moduleList && moduleList.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id} >{item?.name}</option>
                                                )

                                            })}
                                        </select>
                                    </div>
                                </div>
                            }
                            {testdata?.test_type != 'group' &&

                                <div className='col-lg-5' >
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Topic</label>
                                        <select className='form-control' value={topicname}
                                            onChange={(e) => settopicname(e.target.value)}>
                                            <option value="">--Topic---</option>

                                            {topicList && topicList.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id} >{item?.name}</option>
                                                )

                                            })}

                                        </select>
                                    </div>
                                </div>
                            }




                            <div className='col-lg-10'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Question<span class="asterisk">*</span></label>
                                    <CKEditor
                                        editor={Editor}
                                        config={editorConfiguration}
                                        //data={<div dangerouslySetInnerHTML={{ __html: question&&question }} />}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        value={question && question}
                                        onChange={(event, editor) => setquestion(editor.getData())}
                                    />
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Question Hint<span class="asterisk">*</span></label>
                                    <input type="text" className="form-control"
                                        placeholder="Question Hint"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={ques_hint}
                                        onChange={(e) => setques_hint(e.target.value)} />

                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Question Marks<span class="asterisk">*</span></label>
                                    <input type="text" className="form-control"
                                        placeholder="Question Marks"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={ques_marks}
                                        onChange={(e) => setques_marks(e.target.value)} />

                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Answer 1<span class="asterisk">*</span></label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Answer 1"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={answerone}
                                        onChange={(e) => setanswerone(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Answer 2<span class="asterisk">*</span></label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Answer 2"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={answertwo}
                                        onChange={(e) => setanswertwo(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Answer 3<span class="asterisk">*</span></label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Answer 3"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={answerthree}
                                        onChange={(e) => setanswerthree(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='col-lg-5'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Answer 4<span class="asterisk">*</span></label>
                                    <textarea
                                        className="form-control"
                                        placeholder="Answer 4"
                                        style={{ height: '50px', padding: '11px 30px' }}
                                        value={answerfour}
                                        onChange={(e) => setanswerfour(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='col-lg-10'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Select Correct Answer <span class="asterisk">*</span></label>
                                    <select className='form-control' value={correctanswer}
                                        onChange={(e) => setcorrectanswer(e.target.value)}>
                                        <option value="">--Correct Answer---</option>
                                        <option value="1">Answer 1</option>
                                        <option value="2">Answer 2</option>
                                        <option value="3">Answer 3</option>
                                        <option value="4">Answer 4</option>
                                    </select>
                                </div>
                            </div>


                        </div>


                    </div>



                </div>
                <button className='formBtn' onClick={SubmitHandle}>update</button>

            </div>
        </div>
    )
}

export default EditTest