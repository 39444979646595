import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { UpdatetestRequest, GettestRequest, FetchtestRequest, GetGradeRequest, GetGroupRequest, GetSubjectRequest, GetSubjectChangeRequest, GetSyllabusRequest, GetSyllabusChangeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Syllabus from '../curriculum/syllabus/Syllabus'
import { type } from '@testing-library/user-event/dist/type'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditAssessment = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectChangeResponse?.results
    var GroupList = fetchacompanyReducer?.GetGroupResponse?.results
    var SyllabusList = fetchacompanyReducer?.GetSyllabusChangeResponse?.results
    var editdata = fetchacompanyReducer?.GettestResponse?.results

    console.log(editdata)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchtestRequest(obj))
    }, [])

    useEffect(() => {
        let obj = {
            'name': '',
            'group_id': '',
        }
        let obj1 = {
            'name': '',
            'grade_id': '',
            'group_id': '',
            'subject_id': ''
        }
        dispatch(GetSubjectRequest(obj))
        dispatch(GetSyllabusRequest(obj1))
    }, [])

    useEffect(() => {
        dispatch(GetGradeRequest())
        dispatch(GetGroupRequest())
    }, [])

    const [assessmentname, setassessmentname] = useState("")
    const [totalquestion, settotalquestion] = useState("")
    const [assessmentcode, setassessmentcode] = useState("")
    const [fullmarks, setfullmarks] = useState("")
    const [passmarks, setpassmarks] = useState("")
    const [grade, setgrade] = useState("")
    const [syllabus, setsyllabus] = useState("")
    const [group, setgroup] = useState("")
    const [subject, setsubject] = useState("")
    const [time, settime] = useState("")
    const [assessmentdate, setassessmentdate] = useState(null)
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")
    const [level, setlevel] = useState("")
    const [testtype, settesttype] = useState("")
    const [assessmentstatus, setassessmentstatus] = useState("")
    const [description, setdescription] = useState("")

    useEffect(() => {

        var editdata = fetchacompanyReducer?.GettestResponse?.results[0]
        setassessmentname(editdata?.name)
        settotalquestion(editdata?.total_question)
        setassessmentcode(editdata?.code)
        setfullmarks(editdata?.fullmarks)
        setpassmarks(editdata?.passmarks)
        setgrade(editdata?.grade_id)
        setsyllabus(editdata?.syllabus_id)
        setgroup(editdata?.group_id)
        setsubject(editdata?.subject_id)
        // setdob(new Date(editdata?.date_of_birth))
        settime(editdata?.time)
        setlevel(editdata?.level)
        settesttype(editdata?.test_type)
        setassessmentstatus(editdata?.status)
        setdescription(editdata?.name)

        setstarttime(editdata?.start_time)
        setendtime(editdata?.end_time)
        setassessmentdate(new Date(editdata?.date == undefined? new Date(): new Date(editdata?.date)))

    }, [fetchacompanyReducer?.GettestResponse])

    const SubjectHandle = (e) => {
        setgroup(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSubjectChangeRequest(obj))
    }

    const SyllabusHandle = (e) => {
        setsubject(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSyllabusChangeRequest(obj))
    }

    
    const StartTimeHandle = (e) => {
        setstarttime(e.target.value)
        
        settime(e.target.value);
    }

    const EndTimeHandle = (e) => {
        settime(e.target.value)
       
        const start = new Date(`1970-01-01T${starttime}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit'});
        setendtime(formattedEndtime);
    }

    const SubmitHandle = () => {
        if (grade == '') {
            Swal.fire({
                icon: "error",
                text: 'Grade should be must',
            });
        } else if (group == '') {
            Swal.fire({
                icon: "error",
                text: 'Group should be must',
            });
        } else if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject Code should be must',
            });
        } else if (syllabus == '') {
            Swal.fire({
                icon: "error",
                text: 'Syllabus should be must',
            });
        }
        else if (assessmentname == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Name should be must',
            });
        } else if (totalquestion == '') {
            Swal.fire({
                icon: "error",
                text: 'Total Question should be must',
            });
        }
        else if (assessmentcode == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Code should be must',
            });
        } else if (fullmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Fullmarks Type should be must',
            });
        } else if (passmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Passmarks should be must',
            });
        }
        else if (level == '') {
            Swal.fire({
                icon: "error",
                text: 'Level should be must',
            });
        }
        else if (testtype == '') {
            Swal.fire({
                icon: "error",
                text: 'Test Type should be must',
            });
        }
        else if (assessmentstatus == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Status should be must',
            });
        }
        else if (description == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',
            });
        } else {

            let obj = {
                "grade_id": grade,
                "group_id": group,
                "subject_id": subject,
                "syllabus_id": syllabus,
                "name": assessmentname,
                "total_question": totalquestion,
                "code": assessmentcode,
                "fullmarks": fullmarks,
                "passmarks": passmarks,
                "level": level,
                "test_type": testtype,
                "status": assessmentstatus,
                "fullmarks": fullmarks,
            }

            dispatch(UpdatetestRequest({ obj: obj, id: id, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Edit Assessment</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

                <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Assessment Type <span class="asterisk">*</span></label>
                        <select className='form-control' value={testtype}
                                onChange={(e) => settesttype(e.target.value)}>
                                <option value="">--Assessment Type---</option>
                                <option value="group">Group</option>
                                <option value="individual">Individual</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
                            <select className='form-control' value={grade}
                                onChange={(e) => setgrade(e.target.value)}>
                                <option value="">--Select Grade---</option>
                                {GradeList && GradeList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.grade_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Group <span class="asterisk">*</span></label>
                            <select className='form-control' value={group}
                                onChange={SubjectHandle}>
                                <option value="">--Group---</option>
                                {GroupList && GroupList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Subject <span class="asterisk">*</span></label>
                            <select className='form-control' value={subject}
                                onChange={SyllabusHandle}>
                                <option value="">-- Select Subject --</option>
                                {SubjectList && SubjectList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.subject_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Syllabus Name <span class="asterisk">*</span></label>
                            <select className='form-control' value={syllabus}
                                onChange={(e) => setsyllabus(e.target.value)}>
                                <option value="">--Syllabus Name---</option>
                                {SyllabusList && SyllabusList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Assessment Name <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Assessment Name'
                                value={assessmentname}
                                onChange={(e) => setassessmentname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Question Count <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Question Count'
                                value={totalquestion}
                                onChange={(e) => settotalquestion(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Assessment Code <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Assessment Code'
                                value={assessmentcode}
                                onChange={(e) => setassessmentcode(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Full Marks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Full Marks'
                                value={fullmarks}
                                onChange={(e) => setfullmarks(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Pass Marks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Pass Marks'
                                value={passmarks}
                                onChange={(e) => setpassmarks(e.target.value)}
                            />
                        </div>
                    </div>

                        {/* <div className='col-lg-4'>
                            <div className='form-group'>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Assessment Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div> */}
{/* 
                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <select className='form-control' value={time}
                                onChange={EndTimeHandle}>
                                <option value="">--Duration--</option>
                                <option value="30">30</option>
                                <option value="60">60</option>
                                <option value="90">90</option>
                                <option value="120">120</option>
                                <option value="150">150</option>
                                <option value="180">180</option>
                            </select>
                        </div>
                    </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly  />
                            </div>
                        </div> */}

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Level <span class="asterisk">*</span></label>
                            <select className='form-control' value={level}
                                onChange={(e) => setlevel(e.target.value)}>
                                <option value="">--Level---</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Standard">Standard</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Assessment Status <span class="asterisk">*</span></label>
                            <select className='form-control' value={assessmentstatus}
                                onChange={(e) => setassessmentstatus(e.target.value)}>
                                <option value="">--Status---</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-10'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Description <span class="asterisk">*</span></label>
                            <textarea type='text' className='form-control' placeholder='Description'
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            />
                        </div>
                    </div>


                </div>
                <button className='formBtn' onClick={SubmitHandle}>Update</button>
            </div>

        </div>
    )
}

export default EditAssessment