import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddtestRequest, GetGradeRequest, GetGroupRequest, GetSubjectRequest, GetSubjectChangeRequest, GetSyllabusRequest, GetSyllabusChangeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Syllabus from '../curriculum/syllabus/Syllabus'
import { type } from '@testing-library/user-event/dist/type'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from "moment";

const AddAssessment = () => {
    const [assessmentname, setassessmentname] = useState("")
    const [totalquestion, settotalquestion] = useState("")
    const [assessmentcode, setassessmentcode] = useState("")
    const [fullmarks, setfullmarks] = useState("")
    const [passmarks, setpassmarks] = useState("")
    const [grade, setgrade] = useState("")
    const [syllabus, setsyllabus] = useState("")
    const [group, setgroup] = useState("")
    const [subject, setsubject] = useState("")
    const [time, settime] = useState("")
    const [assessmentdate, setassessmentdate] = useState(null)
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")
    const [level, setlevel] = useState("")
    const [testtype, settesttype] = useState("")
    const [assessmentstatus, setassessmentstatus] = useState("")
    const [description, setdescription] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectChangeResponse?.results
    var GroupList = fetchacompanyReducer?.GetGroupResponse?.results
    var SyllabusList = fetchacompanyReducer?.GetSyllabusChangeResponse?.results


    console.log(SyllabusList)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(GetGradeRequest())
        dispatch(GetGroupRequest())
    }, [])

    useEffect(() => {
        let obj = {
            'name': '',
            'group_id': '',
        }
        let obj1 = {
            'name': '',
            'grade_id': '',
            'group_id': '',
            'subject_id': ''
        }
        dispatch(GetSubjectRequest(obj))
        dispatch(GetSyllabusRequest(obj1))
    }, [])

    const SubjectHandle = (e) => {
        setgroup(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSubjectChangeRequest(obj))
    }

    const SyllabusHandle = (e) => {
        setsubject(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSyllabusChangeRequest(obj))
    }

    const StartTimeHandle = (e) => {
        setstarttime(e.target.value)
        
        settime(e.target.value);
    }

    const EndTimeHandle = (e) => {
        settime(e.target.value)
       
        const start = new Date(`1970-01-01T${starttime}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit'});
        setendtime(formattedEndtime);
    }

    // const calculateEndTime = () => {
    //     const start = new Date(`1970-01-01T${starttime}`);
    //     const durationInMinutes = parseInt(time, 10);
    //     const end = new Date(start.getTime() + durationInMinutes * 60000);
    //     const formattedEndtime = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
    //     setendtime(formattedEndtime);
    // }

    const SubmitHandle = () => {
        if (testtype == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Type should be must',
            });
        }
        else if (grade == '') {
            Swal.fire({
                icon: "error",
                text: 'Grade should be must',
            });
        } else if (group == '') {
            Swal.fire({
                icon: "error",
                text: 'Group should be must',
            });
        } else if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject Code should be must',
            });
        } else if (syllabus == '') {
            Swal.fire({
                icon: "error",
                text: 'Syllabus should be must',
            });
        }
        else if (assessmentname == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Name should be must',
            });
        } else if (totalquestion == '') {
            Swal.fire({
                icon: "error",
                text: 'Total Question should be must',
            });
        }
        else if (assessmentcode == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Code should be must',
            });
        } else if (fullmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Fullmarks Type should be must',
            });
        } else if (passmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Passmarks should be must',
            });
        }
        else if (level == '') {
            Swal.fire({
                icon: "error",
                text: 'Level should be must',
            });
        }
        else if (assessmentstatus == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Status should be must',
            });
        }
        else if (description == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',
            });
        } else {

            let obj = {
                "grade_id": grade,
                "group_id": group,
                "subject_id": subject,
                "syllabus_id": syllabus,
                "name": assessmentname,
                "total_question": totalquestion,
                "code": assessmentcode,
                "fullmarks": fullmarks,
                "passmarks": passmarks,
                "level": level,
                "test_type": testtype,
                "status": assessmentstatus,
                "fullmarks": fullmarks,
            }

            dispatch(AddtestRequest({ obj: obj, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Add Assessment</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Assessment Type <span class="asterisk">*</span></label>
                            <select className='form-control' value={testtype}
                                onChange={(e) => settesttype(e.target.value)}>
                                <option value="">--Assessment Type---</option>
                                <option value="group">Group</option>
                                <option value="individual">Individual</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
                            <select className='form-control' value={grade}
                                onChange={(e) => setgrade(e.target.value)}>
                                <option value="">--Select Grade---</option>
                                {GradeList && GradeList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.grade_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Group <span class="asterisk">*</span></label>
                            <select className='form-control' value={group}
                                onChange={SubjectHandle}>
                                <option value="">--Group---</option>
                                {GroupList && GroupList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Subject <span class="asterisk">*</span></label>
                            <select className='form-control' value={subject}
                                onChange={SyllabusHandle}>
                                <option value="">-- Select Subject --</option>
                                {SubjectList && SubjectList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.subject_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Syllabus Name <span class="asterisk">*</span></label>
                            <select className='form-control' value={syllabus}
                                onChange={(e) => setsyllabus(e.target.value)}>
                                <option value="">--Syllabus Name---</option>
                                {SyllabusList && SyllabusList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Assessment Name <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Assessment Name'
                                value={assessmentname}
                                onChange={(e) => setassessmentname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Question Count <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Question Count'
                                value={totalquestion}
                                onChange={(e) => settotalquestion(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Assessment Code <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Assessment Code'
                                value={assessmentcode}
                                onChange={(e) => setassessmentcode(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Fullmarks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Full Marks'
                                value={fullmarks}
                                onChange={(e) => setfullmarks(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Passmarks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Pass Marks'
                                value={passmarks}
                                onChange={(e) => setpassmarks(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Level <span class="asterisk">*</span></label>
                            <select className='form-control' value={level}
                                onChange={(e) => setlevel(e.target.value)}>
                                <option value="">--Level---</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Standard">Standard</option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Select Assessment Status <span class="asterisk">*</span></label>
                            <select className='form-control' value={assessmentstatus}
                                onChange={(e) => setassessmentstatus(e.target.value)}>
                                <option value="">--Status---</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-10'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Description <span class="asterisk">*</span></label>
                            <textarea type='text' className='form-control' placeholder='Description'
                                value={description}
                                onChange={(e) => setdescription(e.target.value)}
                            />
                        </div>
                    </div>


                </div>
                <button className='formBtn' onClick={SubmitHandle}>Save</button>
            </div>

        </div>
    )
}

export default AddAssessment




