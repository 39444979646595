import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import { DeletestudentRequest, AddpropectsRequest, GetAssignEmployeelocationRequest, GetStudentListRequest, GetSubjectRequest, GetTeacherRequest, AddServiceSubjectRequest, AddServicesRequest, AddPricingRequest, AddStaffRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ReactSwitch from 'react-switch';

const AddMembershipList = ({ id }) => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [location1, setlocation1] = useState("")
    const [startupfee, setstartupfee] = useState("")
    const [Assessmentfee, setAssessmentfee] = useState("")
    const [Cancellationfee, setCancellationfee] = useState("")
    const [manualpaymentfee, setmanualpaymentfee] = useState("")
    const [achdiscount, setachdiscount] = useState("")
    const [Latefee, setLatefee] = useState("")
    const [iscashaccepted, setiscashaccepted] = useState(false)
    const [ischeckaccepted, setischeckaccepted] = useState(false)
    const [iscardsaccepted, setiscardsaccepted] = useState(false)
    const [isachaccepted, setisachaccepted] = useState(false)
    const [learningcenterguidelines, setlearningcenterguidelines] = useState("")
    const [homelearningcenterguidelines, sethomelearningcenterguidelines] = useState("")
    const [mediareleaseform, setmediareleaseform] = useState("")
    const [teacherconsentform, setteacherconsentform] = useState("")
    const [leaveunescortedconsentform, setleaveunescortedconsentform] = useState("")
    const [remotedigitalenrollmentemailtext, setremotedigitalenrollmentemailtext] = useState("")
    const [changepaymentmethodrequestemail, setchangepaymentmethodrequestemail] = useState("")
    const [enrollmentconfirmation, setenrollmentconfirmation] = useState("")

    const [learningcenterguidelinesnotes, setlearningcenterguidelinesnotes] = useState("")
    const [homelearningcenterguidelinesnotes, sethomelearningcenterguidelinesnotes] = useState("")
    const [mediareleasenotes, setmediareleasenotes] = useState("")
    const [teacherconsentformnotes, setteacherconsentformnotes] = useState("")
    const [leaveunescortednotes, setleaveunescortednotes] = useState("")
    const [remotedigitalenrollnotes, setremotedigitalenrollnotes] = useState("")
    const [changepaymentmethodnotes, setchangepaymentmethodnotes] = useState("")
    const [enrollmentconfirmationnotes, setenrollmentconfirmationnotes] = useState("")

    const [description, setdescription] = useState("")
    const [amount, setamount] = useState("")
    const [discountname, setdiscountname] = useState("")
    const [ispercentage, setispercentage] = useState("0")
    const [description1, setdescription1] = useState("")
    const [amount1, setamount1] = useState("")
    const [discountname1, setdiscountname1] = useState("")
    const [ispercentage1, setispercentage1] = useState("")
    const [discountList, setdiscountList] = useState([])
    const [docId, setdocId] = useState(null);
    const [checked, setChecked] = useState(false);
    const [moneyfactor, setmoneyfactor] = useState("")

    const [text, settext] = useState("")
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var locationList = fetchacompanyReducer?.GetAssignEmployeelocationResponse?.results

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const [traininfo, settraininfo] = useState([{
        label: '',
        assigndate: null,
        duedate: null,
        completedate: null,
        isdisabled: false
    }]);

    const SubmitPurchaseHandle = async () => {
        if (startupfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Startup Fee should be must',

            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else if (Assessmentfee == '') {
            Swal.fire({
                icon: "error",
                text: 'Assessment Fee should be must',
            });
        } else {

            let obj = {
                "location_id": location1,
                "total_coin_money_factor": moneyfactor,
                "startup_fee": startupfee,
                "assessment_fee": Assessmentfee,
                "cancelation_fee": Cancellationfee,
                "late_fee": Latefee,
                "is_cash_accepted": iscashaccepted == true ? 1 : 0,
                "is_check_accepted": ischeckaccepted == true ? 1 : 0,
                "is_cards_accepted": iscardsaccepted == true ? 1 : 0,
                "is_ach_accepted": isachaccepted == true ? 1 : 0,
                "manual_payment_fee": 1000,
                "ach_discount": 3,
                "learning_center_guidelines": learningcenterguidelines,
                "home_learning_center_guidelines": homelearningcenterguidelines,
                "media_release_form": mediareleaseform,
                "teacher_consent_form": teacherconsentform,
                "leave_unescorted_consent_form": leaveunescortedconsentform,
                "remote_digital_enrollment_email_text": remotedigitalenrollmentemailtext,
                "change_payment_method_request_email": changepaymentmethodrequestemail,
                "enrollment_confirmation": enrollmentconfirmation,
                "learning_center_guidelines_notes": learningcenterguidelinesnotes,
                "online_learning_guidelines_notes": homelearningcenterguidelinesnotes,
                "media_release_form_notes": mediareleasenotes,
                "teacher_consent_form_notes": teacherconsentformnotes,
                "leave_unescorted_consent_form_notes": leaveunescortednotes,
                "remote_digital_enrollment_email_text_notes": remotedigitalenrollnotes,
                "change_payment_method_request_email_notes": changepaymentmethodnotes,
                "enrollment_confirmation_notes": enrollmentconfirmationnotes
            }

            try {
                const response = await ApiConnection.post(`membership-setup-crud/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    // GetPurchaseListService()
                    // setShow(false)
                }
            } catch (e) { }
        }
    }

    useEffect(() => {

        let obj = {
            id: id
        }
        dispatch(GetAssignEmployeelocationRequest(id));
        GetDiscount();

    }, [])

    const handleChange = (val) => {
        setChecked(val);
    };

    const handleShow = () => {
        setShow(true)
    };

    const handleShow1 = () => {
        setShow1(true)
        setShow(false)
    };

    const handleShow2 = () => {
        setShow2(true)
        setShow1(false)
    };

    const handleShow3 = (id) => {
        setShow3(true)
        setShow2(false)
    };

    const handleShow4 = async (id) => {
        console.log("membership-discount-crud", id)
        setdocId(id)
        setShow4(true)
        setShow3(false)

        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production&id=${id}`)

            setdiscountname1(response.data.results[0].name);
            setdescription1(response.data.results[0].description);
            setamount1(response.data.results[0].amount);
            setispercentage1(response.data.results[0].is_percentage);


        } catch (e) { }
    };

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo']
    };

    const learningcenterguidelinesEditorHandle = (value) => {
        var newValue = [...learningcenterguidelines];
        newValue = value.getData()
        setlearningcenterguidelines(newValue);
    }

    const homelearningcenterguidelinesEditorHandle = (value) => {
        var newValue = [...homelearningcenterguidelines];
        newValue = value.getData()
        sethomelearningcenterguidelines(newValue);
    }

    const mediareleaseformEditorHandle = (value) => {
        var newValue = [...mediareleaseform];
        newValue = value.getData()
        setmediareleaseform(newValue);
    }

    const teacherconsentformEditorHandle = (value) => {
        var newValue = [...teacherconsentform];
        newValue = value.getData()
        setteacherconsentform(newValue);
    }

    const leaveunescortedconsentformEditorHandle = (value) => {
        var newValue = [...leaveunescortedconsentform];
        newValue = value.getData()
        setleaveunescortedconsentform(newValue);
    }

    const remotedigitalenrollmentemailtextEditorHandle = (value) => {
        var newValue = [...remotedigitalenrollmentemailtext];
        newValue = value.getData()
        setremotedigitalenrollmentemailtext(newValue);
    }

    const changepaymentmethodrequestemailEditorHandle = (value) => {
        var newValue = [...changepaymentmethodrequestemail];
        newValue = value.getData()
        setchangepaymentmethodrequestemail(newValue);
    }

    const enrollmentconfirmationEditorHandle = (value) => {
        var newValue = [...enrollmentconfirmation];
        newValue = value.getData()
        setenrollmentconfirmation(newValue);
    }

    const Learningcenterreset = (value) => {
        console.log("Reset")
        setlearningcenterguidelines("");
    }

    const homelearningreset = (value) => {

    }

    const mediareleasereset = (value) => {

    }

    const teacherconsentreset = (value) => {

    }

    const Leaveunscortedreset = (value) => {

    }

    const remotedigitalreset = (value) => {

    }

    const changepaymentreset = (value) => {

    }

    const enrollcnfreset = (value) => {

    }



    const GetDiscount = async () => {
        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production`)
            if (response?.status == 200) {
                setdiscountList(response?.data?.results)
            }
        } catch (e) { }
    }

    const DeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`membership-discount-crud/?server_type=production&id=${id}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetDiscount()
                    }
                }
            })
        } catch (e) { }
    }

    const SubmitHandle = async (id) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (discountname == '') {
            Swal.fire({
                icon: "error",
                text: 'Discount Name should be must',
            });
        } else if (amount == '') {
            Swal.fire({
                icon: "error",
                text: 'Amount should be must',
            });
        }

        else {

            let obj = {
                "name": discountname,
                "description": description,
                "is_percentage": ispercentage,
                "amount": amount,
            }

            try {
                const response = await ApiConnection.post(`membership-discount-crud/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetDiscount()
                }
            } catch (e) { }
        }
    }

    const UpdateHandle = async (id) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (discountname1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Discount name should be must',
            });
        } else if (description1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',
            });
        }

        else {

            let obj2 = {
                id: docId
            }

            let obj = {
                "name": discountname1,
                "description": description1,
                "is_percentage": ispercentage1,
                "amount": amount1,
            }


            try {
                const response = await ApiConnection.put(`membership-discount-crud/?server_type=production&id=${docId}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetDiscount()
                }
            } catch (e) { }
        }
    }

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Add Membership </h3>
                </div>

                <div className='col-lg-6'>
                    <div className='form-group'>
                        <label className='selectlabel'>Select Location Name</label>
                        <select className='form-control' value={location1}
                            onChange={(e) => setlocation1(e.target.value)}>
                            <option value="">--Select Location---</option>
                            {locationList && locationList.map((item, i) => {
                                return (
                                    <option key={i} value={item?.id}>{item?.location_name} {item?.is_virtual_location == 1 ? "(Hybrid Location)" : ""}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='form-group'>
                        <label className='selectlabel'>Total Coin/Money Factor</label>
                        <input type='number' className='form-control' placeholder='Money Factor Value'
                                value={moneyfactor}
                                onChange={(e) => setmoneyfactor(e.target.value)}
                            />
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>Startup Fee</label>
                            <input type='number' className='form-control' placeholder='Startup Fee '
                                value={startupfee}
                                onChange={(e) => setstartupfee(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 ml-5'>
                        <div className='form-group'>
                            <input type='checkbox'
                                value={Latefee}
                                onChange={(e) => setLatefee(e.target.value)}
                            /> Cash Accepted
                        </div>
                    </div>

                </div>

                <div className='d-flex'>
                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>Assessment Fee</label>
                            <input type='number' className='form-control' placeholder='Assessment Fee '
                                value={Assessmentfee}
                                onChange={(e) => setAssessmentfee(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6 ml-5'>
                        <div className='form-group'>
                            <input type='checkbox'
                                value={Latefee}
                                onChange={(e) => setLatefee(e.target.value)}
                            /> Checks Accepted
                        </div>
                    </div>

                </div>

                <div className='d-flex'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>A/C Closing Fee</label>
                            <input type='number' className='form-control' placeholder='A/C Closing Fee '
                                value={Cancellationfee}
                                onChange={(e) => setCancellationfee(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6 ml-5'>
                        <div className='form-group'>
                            <input type='checkbox'
                                value={Latefee}
                                onChange={(e) => setLatefee(e.target.value)}
                            /> Cards Accepted
                        </div>
                    </div>

                </div>

                <div className='d-flex'>
                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>Late Fee</label>
                            <input type='number' className='form-control' placeholder='Late Fee '
                                value={Latefee}
                                onChange={(e) => setLatefee(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6 ml-5'>
                        <div className='form-group'>
                            <input type='checkbox'
                                defaultChecked={isachaccepted}
                                onChange={(e) => setisachaccepted(!isachaccepted)}
                            /> ACH Accepted
                        </div>
                    </div>

                </div>


                <div className='d-flex'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='selectlabel'>Manual Payment Fee</label>
                            <input type='number' className='form-control' placeholder='Manual Payment Fee '
                                value={manualpaymentfee}
                                onChange={(e) => setmanualpaymentfee(e.target.value)}
                            />
                        </div>
                    </div>

                    {isachaccepted === true && (
                    <div className='col-lg-6 ml-5'>
                        <label className='selectlabel ml-4'>ACH Discount</label>
                        <div className='form-group'>
                            <input type='checkbox'
                                value={Latefee}
                                onChange={(e) => setLatefee(e.target.value)}
                            />  &nbsp;
                            <input type='text'
                                value={achdiscount} placeholder='ACH Discount'
                                onChange={(e) => setachdiscount(e.target.value)}
                            />
                        </div>
                    </div>
                    )}

                </div>

                <hr></hr>

                <div >
                    <div className='mb-4'>
                        <h5> Discount </h5>
                    </div>

                    <div className='button-part mb-4'>
                        <ul>
                            <li>
                                <NavLink className='formBtn1' onClick={handleShow3}><i className="fa-solid fa-plus"></i> Add Discount</NavLink>
                            </li>

                        </ul>
                    </div>

                    <div className='table-responsive mb-4'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Percentage</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {discountList && discountList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.name}</td>
                                            <td>{item?.description}</td>
                                            <td>{item?.amount}</td>
                                            <td>{item?.is_percentage}</td>
                                            <td>
                                            <NavLink onClick={() => handleShow4(item?.id)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                            <button onClick={() => DeleteHandle(item?.id)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}



                            </tbody>
                        </table>
                    </div>

                </div>

                <hr></hr>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>In Person Learning Guidelines
                            <button className='formBtn1 resetBtn' onClick={() => Learningcenterreset()}><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => learningcenterguidelinesEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={learningcenterguidelinesnotes}
                                onChange={(e) => setlearningcenterguidelinesnotes(e.target.value)}
                            />
                        </div>
                    </div>

                </div>

                {/* onChange={(e) => { setlearningcenterguidelines(e.target.value) }} */}


                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Online Learning Guidelines
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => homelearningcenterguidelinesEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={homelearningcenterguidelinesnotes}
                                onChange={(e) => sethomelearningcenterguidelinesnotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Media Release Form
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => mediareleaseformEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={mediareleasenotes}
                                onChange={(e) => setmediareleasenotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Teacher Consent Form
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => teacherconsentformEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={teacherconsentformnotes}
                                onChange={(e) => setteacherconsentformnotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Leave Unescorted Consent Guidelines
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => leaveunescortedconsentformEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={leaveunescortednotes}
                                onChange={(e) => setleaveunescortednotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Remote Digital Enrollment Email Text
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => remotedigitalenrollmentemailtextEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={remotedigitalenrollnotes}
                                onChange={(e) => setremotedigitalenrollnotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Change Payment Method Request Email
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => changepaymentmethodrequestemailEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={changepaymentmethodnotes}
                                onChange={(e) => setchangepaymentmethodnotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-5 mb-5'>
                    <div className='form-group'>
                        <label className='selectlabel d-flex' style={{ fontSize: '16px', fontWeight: '500' }}>Enrollment Confirmation
                            <button className='formBtn1 resetBtn'><i class="fa fa-refresh"></i> Reset</button>
                        </label>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data="<p>Enter Text</p>"
                            onChange={(event, editor) => enrollmentconfirmationEditorHandle(editor)}
                        />
                        <div className='form-group mt-1'>
                            <textarea className='form-control' placeholder='Enter Notes '
                                value={enrollmentconfirmationnotes}
                                onChange={(e) => setenrollmentconfirmationnotes(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className='d-flex justify-content-end mb-2 mr-2'>
                <button className='formBtn' onClick={SubmitPurchaseHandle}>Save</button>
                <button className='formBtn mr-2 ml-2' >Cancel</button>
                <NavLink to={`/${userinfo?.company_slug}/ServiceList`} className='formBtn'>Return to Services & Pricing</NavLink>
            </div>

            <Modal show={show3} onHide={handleClose3} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Discount</Modal.Title>
                    <button onClick={handleClose3} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Enter Discount Name</label>
                                <input type='text' className='form-control' placeholder='Discount Name '
                                    value={discountname}
                                    onChange={(e) => setdiscountname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Enter Description</label>
                                <input type='text' className='form-control' placeholder='Description'
                                    value={description}
                                    onChange={(e) => setdescription(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group d-flex align-item-center'>
                                <label className='selectlabel mr-2'>Is Percentage</label>
                                <ReactSwitch checked={checked} onChange={handleChange} />
                            </div>
                        </div>

                        {checked === !true && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Amount</label>
                                    <input type='number' className='form-control' placeholder='Amount'
                                        value={amount}
                                        onChange={(e) => setamount(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {checked === true && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Percentage</label>
                                    <input type='number' className='form-control' placeholder='Percentage'
                                        value={ispercentage} maxLength="3"
                                        onChange={(e) => setispercentage(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4} size="lg">
                <Modal.Header >
                    <Modal.Title>Edit Discount</Modal.Title>
                    <button onClick={handleClose4} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Enter Discount Name</label>
                                <input type='text' className='form-control' placeholder='Discount Name '
                                    value={discountname1}
                                    onChange={(e) => setdiscountname1(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Enter Description</label>
                                <input type='text' className='form-control' placeholder='Description'
                                    value={description1}
                                    onChange={(e) => setdescription1(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group d-flex align-item-center'>
                                <label className='selectlabel mr-2'>Is Percentage</label>
                                <ReactSwitch checked={checked} onChange={handleChange} />
                            </div>
                        </div>

                        {checked === !true && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Amount</label>
                                    <input type='number' className='form-control' placeholder='Amount'
                                        value={amount1}
                                        onChange={(e) => setamount1(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {checked === true && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Enter Percentage</label>
                                    <input type='number' className='form-control' placeholder='Percentage'
                                        value={ispercentage1}
                                        onChange={(e) => setispercentage1(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdateHandle}>Update</button>

                </Modal.Footer>
            </Modal>

        </div>


    )
}

export default AddMembershipList