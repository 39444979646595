import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { AddExistingRequest, DeleteQuestionRequest, DownloadQuestionRequest, FetchtestRequest, GetExistingQuestionRequest, GetExistingTestRequest, GetquesRequest, UpdateorderListRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';

const list = [
    {
        id: 'one',
        question: "Question one",
    },
    {
        id: 'two',
        question: "Question two",
    },
    {
        id: 'three',
        question: "Question three",
    },
]

const AssessmentDetails = () => {
    const [qList, setqList] = useState([])
    const [show, setShow] = useState(false);
    const [TestId, setTestId] = useState("");
    const [QuestionId, setQuestionId] = useState([])

    console.log(QuestionId)


    const handleClose = () => setShow(false);
    const { id, pros } = useParams()
    const location = useLocation()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var viewdata = fetchacompanyReducer?.GettestResponse?.results[0]
    var NewOrder = fetchacompanyReducer?.UpdateorderListResponse?.results
    var ExistingQuestion = fetchacompanyReducer?.GetExistingResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetExistingTestResponse?.results

    const as_id = id


    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchtestRequest(obj))

    }, [])

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [])

    useEffect(() => {
        var testdata = fetchacompanyReducer?.GetquesResponse?.results
        console.log("acctual", testdata)
        setqList(testdata)
    }, [fetchacompanyReducer?.GetquesResponse])



    const HandleonDrag = (result) => {
        const items = Array.from(qList)
        const [saveItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, saveItem)

        setqList(items)

        dispatch(UpdateorderListRequest({ obj: { "results": items }, test_id: id }))

        console.log("achanges", items)
    }


    useEffect(() => {

        setqList(NewOrder)

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.UpdateorderListResponse])


    const DownloadHandle = () => {
        dispatch(DownloadQuestionRequest(id))
    }


    useEffect(() => {

        setqList(NewOrder)

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.DeleteQuestionResponse])

    useEffect(() => {

        setShow(false)
        setqList(NewOrder)

        let obj = {
            id: id
        }
        dispatch(GetquesRequest(obj))

    }, [fetchacompanyReducer?.AddExistingResponse])

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteQuestionRequest({ id: id, test_id: as_id }))

                if (fetchacompanyReducer?.DeleteQuestionResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }

    const handleShow = () => {
        // setaddrowId(tid)
        setShow(true)
        dispatch(GetExistingTestRequest(as_id))

    };

    const TestHandle = (value) => {
        setTestId(value)
        dispatch(GetExistingQuestionRequest(value))
    }

    const MultipleQuestionHandle = (event, row) => {


        var updatedList = [...QuestionId];
        if (event.target.checked) {
            updatedList = [...QuestionId, event.target.value];
        } else {
            updatedList.splice(QuestionId.indexOf(event.target.value), 1);
        }
        setQuestionId(updatedList);

    }


    const AddExistingQuestion = () => {
        if (TestId == '') {
            Swal.fire({
                icon: "error",
                text: 'Test should be must',
            });
        }
        else if (QuestionId == '') {
            Swal.fire({
                icon: "error",
                text: 'Question should be must',
            });
        }
        else {
            let obj = {
                "ex_test_id": TestId,
                "question_id": QuestionId
            }
            dispatch(AddExistingRequest({ obj: obj, test_id: as_id }))
        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>

                <div className='view-page'>
                    <div className='form-group d-flex align-items-center justify-content-between'>
                        <h6>Assessment information</h6>
                        <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                    </div>
                    <div className='form-group'>
                        <hr />
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Assessment Name:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Assessment Code:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.code}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Grade:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.grade_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Subject:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.subject_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Syllabus:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.grade_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Group:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.group_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Question Count:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{moment(viewdata?.date_of_birth).format('DD-MM-YYYY')}</b>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Question Count:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.total_question}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Fullmarks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.fullmarks}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Passmarks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.passmarks}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Time:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.time}</b>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Level:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.level}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Type:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.test_type}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Status:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.status}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Description:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.description}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <a id="DownloadQuestion" target='_blank'></a>

                <div className='innerlayout p-3'>

                    <div className='form-group text-right d-flex justify-content-end'>
                        <button onClick={DownloadHandle} className='formBtn1 mr-2' style={{ display: 'inline-block', background: '#dd1e88' }}>Download Question</button>
                        <button className='formBtn1 mr-2' onClick={handleShow}>Create Existing Question</button>
                        <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Test/Addtest/${id}`} style={{ display: 'inline-block' }}> Create New Question</NavLink>
                    </div>
                    {qList && qList.length > 0 ?

                        <>
                            <ul className='q-table-head'>
                                <li>
                                    <h2>Question No</h2>
                                    <h2>Question Name</h2>
                                    <h2>Grade Name</h2>
                                    <h2>Action</h2>
                                </li>
                            </ul>
                            <DragDropContext onDragEnd={HandleonDrag}>
                                <Droppable droppableId='characters'>
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef} className='q-table-body'>
                                            {qList && qList.map(({ id, grade_name, question, display_order }, index) => {
                                                return (
                                                    <Draggable key={id} draggableId={(display_order).toString()} index={index}>
                                                        {(provided) => (
                                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <h3><span><i className="fa-solid fa-arrows-up-down-left-right"></i></span> {display_order}</h3>
                                                                <h3><div
                                                                    dangerouslySetInnerHTML={{ __html: question }}
                                                                /></h3>
                                                                <h3>{grade_name}</h3>
                                                                <h3>
                                                                    {/* <NavLink to={`/${userinfo?.company_slug}/assesment/view-question/${as_id}/${id}`}><i className="fa-solid fa-eye" style={{marginRight: '8px'}}></i></NavLink> */}
                                                                    <NavLink to={`/${userinfo?.company_slug}/Test/edit-question/${as_id}/${id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                                    <button className='table-btn' onClick={() => DeleteHandle(id)}><i className="fa-solid fa-trash"></i></button>
                                                                </h3>
                                                            </li>
                                                        )}

                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>


                        :
                        <h4>No Question </h4>

                    }

                    {/* <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Question Number</th>
                                    <th>Grade</th>
                                    <th>Subject</th>
                                    <th>Module</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testdata && testdata.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.question_number}</td>
                                            <td>{item?.grade_name}</td>
                                            <td>{item?.subject_name}</td>
                                            <td>{item?.module_name}</td>
                                            <td>

                                                <NavLink to={`/${userinfo?.company_slug}/Test/Edittest/${item?.test_id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                <button><i className="fa-solid fa-trash"></i></button>

                                              
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> */}

                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Existing Question</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Test</label>
                                <select className='form-control' value={TestId} onChange={(e) => TestHandle(e.target.value)}>
                                    <option>--select--</option>
                                    {ExistingTest && ExistingTest
                                        .filter((list) => list.test_type !== "group")
                                        .map((list, index) => (
                                            <option key={index} value={list.id}>{list.name}</option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Question</label>
                                <Dropdown className='custom-dropdown-checkbox'>
                                    <Dropdown.Toggle variant="success">
                                        {QuestionId.length > 0 ? 'select ' + QuestionId.length : 'select Question'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul className='existingQuestion-list'>

                                            {ExistingQuestion && ExistingQuestion.map((list, i) => {
                                                return (
                                                    <li>
                                                        <input type="checkbox" id={i} value={list.id} onChange={(e) => MultipleQuestionHandle(e, list)} />
                                                        <label htmlFor={i}>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: list?.question }}
                                                            />
                                                        </label>
                                                    </li>
                                                )
                                            })}


                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddExistingQuestion}>Add</button>

                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default AssessmentDetails